<template>
  <div></div>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
  },
  data: function () {
    return {
      //
    };
  },
  mounted() {
    document.addEventListener("touchstart", (e) => {
      this.playMusic();
    });
  },
  methods: {
    playMusic() {
      let music = document.getElementById("content-music");
      console.log(music);
      if (music) {
        music.play();
      }
    },
  },
  watch: {
    //
  },
};
</script>

<style>
</style>
